/* eslint-disable sort-keys-fix/sort-keys-fix */
import { DefaultGenerics, Route } from "@tanstack/react-location"
import { lazy } from "react"
import { linkRoutes } from "src/App/link/routes"
import { playRoutes } from "src/App/play/routes"
import { truthy } from "src/helpers/arrays"
import { getEnv } from "src/helpers/env"
import { designSystemRoutes } from "./designSystem/routes"
import { settingsRoutes } from "./settings/routes"

// Misc
const Root = lazy(() => import("./Root"))
const HomePage = lazy(() => import("./homePage"))
const Organization = lazy(() => import("./Organization"))

// Landings
const InviteRequest = lazy(() => import("./landings/InviteRequest"))
const Invitation = lazy(() => import("./landings/Invitation"))
const NewOrganization = lazy(() => import("./landings/NewOrganization"))
const NotFound = lazy(() => import("src/components/oopsies/NotFound"))

export const portalTree: Route<DefaultGenerics> = {
  path: "/",
  element: <Root />,
  children: [
    // Organization-less landing pages
    { path: "invitation/:invitationCode", element: <Invitation /> },

    // Old portal routes
    // TODO: move all of them to new portal routes
    {
      path: "organizations/new",
      element: <NewOrganization />
    },
    {
      path: "organizations/:orgUuid",
      element: <Organization />,
      children: [
        {
          path: "invite_request/:userUuid",
          element: <InviteRequest />
        },

        // App routes
        linkRoutes,
        playRoutes,
        settingsRoutes,

        // Home page
        { path: "home", element: <HomePage /> },

        // Fallback
        { element: <NotFound /> }
      ]
    },

    ["local", "dev"].includes(getEnv()) && designSystemRoutes,

    { element: <NotFound /> }
  ].filter(truthy)
}
